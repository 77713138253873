import React from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'

import Login from './login/index'
import './OfflineRouter.scss'

class OfflineLayout extends React.Component {
  render() {
    return (
      <div className="offlineLayout">
        <span className="offlineLayout__logo">
          FASTER<span>BOARD</span>
        </span>
        <div className="offlineLayout__container">
          <Switch>
            <Route exact path="/offline/" component={Login} />
            <Redirect to="/offline/" />
          </Switch>
        </div>
      </div>
    )
  }
}

export default OfflineLayout
