import React from 'react'
import logo from './logo.svg'
import './App.css'
import UsersRecents from './UsersRecents'
import UserDetails from './UserDetails'
import ProgramsStats from './ProgramsStats'
import API from '../../../tools/fetch'

class DataWrapper extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      datas: {
        programs: [],
        users: [],
      },
      errored: false,
      pwded: true, //false
      login: '',
      password: '',
      filtered: 'lastView',
    }
  }
  componentDidMount() {
    this.loadDatas()
  }
  async loadDatas() {
    //loadData
    const request = await API.get('/datas/user/all')
    this.setState(
      {
        datas: request.data,
      },
      () => setTimeout(() => this.setState({ loaded: true }), 2000),
    )
  }
  render() {
    if (!this.state.pwded) return <div className="AppB"></div>
    if (!this.state.loaded)
      return (
        <div className="AppB">
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <p>Chargement des données...</p>
          </header>
        </div>
      )
    if (this.state.filtered === 'lastView')
      return (
        <div className="AppB">
          <header className="App-header">
            <h1>Suivi des utilisateurs BtoC</h1>
            {this.getFilters()}
            <UsersRecents users={this.state.datas.users} programs={this.state.datas.programs} />
          </header>
        </div>
      )
    if (this.state.filtered === 'brute')
      return (
        <div className="AppB">
          <header className="App-header">
            <h1>Suivi des utilisateurs BtoC</h1>
            {this.getFilters()}
            <div className="userNoAddContainer">
              {this.state.datas.users.map((u) => (
                <UserDetails user={u} programs={this.state.datas.programs} />
              ))}
            </div>
          </header>
        </div>
      )
    if (this.state.filtered === 'dataPrograms')
      return (
        <div className="AppB">
          <header className="App-header">
            <h1>Suivi des utilisateurs BtoC</h1>
            {this.getFilters()}
            <div className="userNoAddContainer">
              <ProgramsStats users={this.state.datas.users} programs={this.state.datas.programs} />
            </div>
          </header>
        </div>
      )
    return (
      <div className="AppB">
        <header className="App-header">
          <h1>Suivi des utilisateurs payants</h1>
          {this.getFilters()}
          <UsersRecents users={this.state.datas.users} programs={this.state.datas.programs} />
        </header>
      </div>
    )
  }

  getFilters = () => {
    let { filtered } = this.state
    if (filtered === 'lastView')
      return (
        <p className="filters">
          Ordre : [A regardé récemment] |{' '}
          <a onClick={() => this.setState({ filtered: 'brute' })}>Inscription récente</a> |{' '}
          <a onClick={() => this.setState({ filtered: 'dataPrograms' })}>Notes programmes</a>
        </p>
      )
    if (filtered === 'brute')
      return (
        <p className="filters">
          Ordre : <a onClick={() => this.setState({ filtered: 'lastView' })}>A regardé récemment</a> | [Inscription
          récente] | <a onClick={() => this.setState({ filtered: 'dataPrograms' })}>Notes programmes</a>
        </p>
      )
    if (filtered === 'dataPrograms')
      return (
        <p className="filters">
          Ordre : <a onClick={() => this.setState({ filtered: 'lastView' })}>A regardé récemment</a> |{' '}
          <a onClick={() => this.setState({ filtered: 'brute' })}>Inscription récente</a> | [Notes programmes]
        </p>
      )
  }
}

export default DataWrapper
