import moment from 'moment'
import React from 'react'
import { ChevronRight } from 'react-feather'
import { connect } from 'react-redux'
import theme from '../../../theme'
import './App.css'

class UserDetails extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  async componentDidMount() {}
  render() {
    const { user, programs } = this.props
    let tpsTotal = 0
    let tpsOther = {}

    user.trackers.forEach((t) => {
      tpsTotal += t.counting
      if (t.programId) {
        tpsOther[t.programId] += t.counting
      }
    })
    let programsStarted = {}
    programs.forEach((prog) => {
      if (!programsStarted[prog.id]) {
        prog.episodes.forEach((ep) => {
          user.views.forEach((v) => {
            if (v.episodeId === ep.id) programsStarted[prog.id] = true
          })
        })
      }
    })
    return (
      <div className="userContainer2">
        <p style={{ marginBottom: '5px' }}>
          <span className={'fnln'}>
            {user.firstname} {user.lastname}
          </span>{' '}
          <span className={'companyname'}>@{user.company.name}</span>
        </p>
        <span className={'email'}>
          #{user.id} - {this.props.user.email}
        </span>
        {Object.keys(programsStarted).length === 0 && <div>Aucun programme commencé</div>}
        {Object.keys(programsStarted).map((programId) => {
          let program = programs.find((p) => parseInt(programId) === p.id)
          let pur = user.purchases.find((p) => program.id === p.programId)
          let percent = this.getPercentAvancement(program, user)
          return (
            <div
              style={{
                borderWidth: 0,
                borderLeftWidth: '7px',
                borderColor: theme.colors.primary.dark,
                borderStyle: 'solid',
              }}
            >
              <p className="progTitle" style={{ paddingTop: '8px' }}>
                <b style={{ color: theme.colors.primary.regular, marginLeft: '10px' }}>
                  {' '}
                  {program.title.replace('$', '')} -
                  <span
                    style={{
                      color:
                        percent === 100 ? theme.colors.primary.regular : percent < 50 ? theme.colors.error : `#edc12f`,
                    }}
                  >
                    {' '}
                    {percent}%
                  </span>
                </b>

                {this.props.userD.premiumCompany.name === 'Fasterclass' && (
                  <span>
                    {' '}
                    - Origine :{' '}
                    {pur
                      ? `${pur.stripePrice} - Date d'achat : ${moment(pur.date).format('DD/MM/YY HH:mm:ss')}`
                      : user.premiumCompany
                      ? 'User premium'
                      : 'Non possédé'}
                  </span>
                )}
              </p>
              <div className="tableContainer">
                <table>
                  <tr>
                    <th style={{ borderLeftWidth: 0, paddingLeft: '6px' }}>Titre</th>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      return (
                        <th>
                          {e.order} - {e.title.substring(0, 40)}
                          {e.title.length > 40 && '...'}
                        </th>
                      )
                    })}
                  </tr>
                  <tr>
                    <th style={{ borderLeftWidth: 0, paddingLeft: '6px' }}>Avancement</th>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)
                      if (!view || (!view.complete && view.currentDuration === 0)) return <td></td>
                      if (view.complete)
                        return (
                          <td
                            style={{
                              height: 20,
                              padding: 0,
                              margin: 0,
                              backgroundColor: theme.colors.primary.dark,
                            }}
                          >
                            <div></div>
                          </td>
                        )
                      let width = Math.round((view.currentDuration * 150) / e.duration)
                      return (
                        <td
                          style={{
                            height: 20,
                            padding: 0,
                            margin: 0,
                          }}
                        >
                          <span>
                            {timerFormater(view.currentDuration)} / {timerFormater(e.duration)}{' '}
                          </span>
                          <div
                            style={{
                              height: 6,
                              backgroundColor: theme.colors.primary.darker,
                            }}
                          >
                            <div
                              style={{
                                height: 6,
                                width,
                                backgroundColor: theme.colors.primary.regular,
                              }}
                            ></div>
                          </div>
                        </td>
                      )
                    })}
                  </tr>
                  <tr>
                    <th style={{ borderLeftWidth: 0, paddingLeft: '6px' }}>Note</th>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view || view.note === -1) return <td></td>
                      return <td>{view.note + 1}⭐</td>
                    })}
                  </tr>
                  <tr>
                    <th style={{ borderLeftWidth: 0, paddingLeft: '6px' }}>Date début</th>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view) return <td></td>
                      return <td>{moment(view.createdAt).format('DD/MM/YY HH:mm:ss')}</td>
                    })}
                  </tr>
                  <tr>
                    <th style={{ borderLeftWidth: 0, paddingLeft: '6px' }}>Date dernière vue</th>
                    {program.episodes.map((e) => {
                      if (e.trailer) return null
                      let view = user.views.find((v) => v.episodeId === e.id)

                      if (!view) return <td></td>
                      return <td>{moment(view.date).format('DD/MM/YY HH:mm:ss')}</td>
                    })}
                  </tr>
                </table>
              </div>
            </div>
          )
        })}
        <p>⏳ Temps passé sur Fasterclass : Environ {Math.round((tpsTotal * 1.1) / 2)} minutes</p>
      </div>
    )
  }
  getPercentAvancement = (program, user) => {
    let nb = 0
    let total = 0
    program.episodes.forEach((ep) => {
      if (!ep.trailer && ep.chapter.toUpperCase() !== 'BONUS') {
        let view = user.views.find((v) => v.episodeId === ep.id)
        if (view) {
          if (view.complete) {
            nb += 10
            total += 10
          } else {
            const nbe = (view.currentDuration * 10) / ep.duration
            console.log(nbe)
            if (nbe > 9.5) {
              nb += 10
            } else {
              nb += Math.round(nbe)
            }
            total += 10
          }
        } else {
          total += 10
        }
      }
    })
    if (total <= 0) return 0
    return Math.round((nb * 100) / total)
  }
}

function timerFormater(time) {
  var minutes = Math.floor(time / 60)
  var seconds = Math.floor(time - minutes * 60)
  return `${minutes >= 10 ? minutes : '0' + minutes}:${seconds >= 10 ? seconds : '0' + seconds}`
}

export default connect((store) => ({
  programs: store.programs.programs,
  userD: store.user.data,
}))(UserDetails)
