import { call, put, select, takeLatest } from 'redux-saga/effects'
import {
  APP_OPENED,
  PROGRAM_OPENED,
  EPISODE_READING,
  EPISODE_ENDED,
  PROGRAM_ENDED,
  APP_RUNNING,
  APP_RUNNING_TRACK_FAIL,
  APP_RUNNING_TRACK_SUCCESS,
  TRACKER_SET_PROGRAM,
  EPISODE_OPENED,
  USER_JUST_REGISTERED,
  OPENNING_RESSOURCE,
} from './constants'
import { IStore } from '..'
import { IUserObject } from '../user/reducer'
import { trackingPush } from './actions'
import { ITrackerState } from './reducer'
import { IEpisodeObject, IProgramObject } from '../programs/reducer'
import { isPlayableProgram, isProgramCompleted } from '../programs/tools'
import { IViewExtended } from '../reader/reducer'
import { PROGRAMS_FETCH_SUCCESS } from '../programs/constants'
import Intercom from '../../tools/intercom'

export default function* trackerWatcher(): IterableIterator<any> {
  yield takeLatest([APP_OPENED], openingApp)
  yield takeLatest([APP_RUNNING], runningApp)
  // @ts-ignore
  yield takeLatest([PROGRAM_OPENED], openningProgram)
  // @ts-ignore
  yield takeLatest([EPISODE_OPENED], openningEpisode)
  // @ts-ignore
  yield takeLatest([EPISODE_READING], readingEpisode)
  yield takeLatest([USER_JUST_REGISTERED], userRegistrationDelay)
  // @ts-ignore
  yield takeLatest([OPENNING_RESSOURCE], openningRessource)
  // @ts-ignore
  yield takeLatest([EPISODE_ENDED], endedEpisode)
  // @ts-ignore
  yield takeLatest([PROGRAMS_FETCH_SUCCESS], setOwnedPrograms)
  // @ts-ignore
  return yield takeLatest([PROGRAM_ENDED], endedProgram)
}

function* setOwnedPrograms({ payload }: { payload: IProgramObject[] }): any {
  payload.forEach((program) => {
    if (program.purchase && program.purchase.status === 'GOT') {
      Intercom.logEvent(`programme-${program.id}-possédé`, { programme: program.title.replace('$', '') })
    }
  })
}

function* openingApp(): any {
  const user: IUserObject = yield select((state: IStore) => state.user.data)
  /*yield put({
    type: TRACKER_SET_PROGRAM,
    payload: { programId: undefined },
  })
  yield put({
    type: APP_RUNNING,
    payload: {},
  })*/

  let userDatas: any = undefined
  if (user && user.id !== 0) {
    userDatas = {
      email: user.email,
      name: `${user.firstname}`,
      user_id: `fasterclass-user-${user.id}`,
      user_hash: user.userHash,
      companies: [
        {
          name: user.company?.name || '',
          company_id: `company-${user.company?.id}` || '',
        },
      ],
    }
    Intercom.init(userDatas)
  }
}
function* runningApp(): any {
  const tracking: ITrackerState = yield select((state: IStore) => state.tracker)
  const vv: boolean = yield call(trackingPush, { programId: tracking.currentProgramId, count: tracking.count })
  if (!vv) {
    return yield put({
      type: APP_RUNNING_TRACK_FAIL,
      payload: {},
    })
  }
  return yield put({
    type: APP_RUNNING_TRACK_SUCCESS,
    payload: {},
  })
}

function* openningProgram({ payload }: { payload: { program: IProgramObject } }): any {
  yield put({
    type: TRACKER_SET_PROGRAM,
    payload: { programId: payload.program.id },
  })
  Intercom.logEvent(`programme-${payload.program.id}-ouvert`, { programme: payload.program.title.replace('$', '') })
}

function* userRegistrationDelay({ payload }: any): any {
  setTimeout(() => Intercom.logEvent('user-inscription', {}), 5000)
}
function* endedProgram({ payload }: { payload: { program: IProgramObject } }): any {
  Intercom.logEvent(`programme-${payload.program.id}-terminé`, { programme: payload.program.title.replace('$', '') })
  Intercom.logEvent(`programme-terminé`, { programme: payload.program.title.replace('$', '') })
}
function* openningEpisode({ payload }: { payload: { episode: IEpisodeObject } }): any {
  //Intercom.logEvent(`episode-ouvert`, { episode: payload.episode.title })
}
function* openningRessource({ payload }: { payload: { id: number; title: string } }): any {
  Intercom.logEvent(`ressource-ouverte`, { titre: payload.title, id: payload.id })
}
function* readingEpisode({ payload }: { payload: { episode: IEpisodeObject } }): any {
  Intercom.logEvent('episode-lecture', { episode: payload.episode.title })
}
function* endedEpisode({ payload }: { payload: { episode: IEpisodeObject; program: IProgramObject } }): any {
  const views: IViewExtended[] = yield select((state: IStore) => state.reader.views)
  if (isProgramCompleted(payload.program, views)) {
    yield put({
      type: PROGRAM_ENDED,
      payload: { program: payload.program },
    })
  }
  if (payload.episode.free && !isPlayableProgram(payload.program)) {
    Intercom.logEvent(`free-episode-${payload.episode.id}-terminé`, { episode: payload.episode.title })
  } else {
    Intercom.logEvent(`programme-${payload.program.id}-episode-terminé`, { episode: payload.episode.title })
  }
}
