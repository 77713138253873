import { store } from './store'
import { Provider } from 'react-redux'
import PagesRouter from './pages/Router'
import { initToken } from './tools/token'
import React from 'react'
import { Router, Switch, Route, Redirect } from 'react-router-dom'
import history from './tools/history'

function App() {
  initToken()
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route
            path="/"
            render={() => {
              return (
                <div>
                  <PagesRouter />
                </div>
              )
            }}
          />
          <Redirect to="/offline/" />
        </Switch>
      </Router>
    </Provider>
  )
}

export default App
