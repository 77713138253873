import React, { CSSProperties } from 'react'
import './Button.scss'
import loader from '../../assets/images/loader.gif'

interface IProps {
  children: JSX.Element | (string | JSX.Element)[] | string
  onClick: () => void
  locked?: boolean
  loading?: boolean
  theme?: 'primary' | 'grey' | 'greyLight'
  style?: CSSProperties
}

export default class Button extends React.Component<IProps> {
  public render(): JSX.Element {
    return (
      <div
        className={`button__base button__${this.props.theme || 'primary'}`}
        style={this.props.style}
        onClick={this.onClick}
      >
        {this.props.loading ? (
          <img src={loader} className="button__loaderimg" width="15" height="15" />
        ) : (
          this.props.children
        )}
      </div>
    )
  }
  private onClick = () => {
    if (!this.props.locked && !this.props.loading) {
      this.props.onClick()
    }
  }
}
