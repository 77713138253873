import { takeLatest, put, call, select } from 'redux-saga/effects'
import { PROGRAMS_FETCH, PROGRAMS_FETCH_SUCCESS, PROGRAMS_FETCH_REQUESTING, PROGRAMS_FETCH_ERROR } from './constants'
import { programsFetchAction, viewsUpdateAction } from './actions'
import { IProgramObject } from './reducer'
import { IStore } from '..'
import { IViewExtended } from '../reader/reducer'
import { VIEWS_UPDATE, VIEWS_UPDATE_SUCCESS } from '../reader/constants'

export default function* userWatcher(): IterableIterator<any> {
  yield takeLatest([VIEWS_UPDATE], viewsUpdate)
  return yield takeLatest([PROGRAMS_FETCH], programsFetch)
}

function* programsFetch({ payload }: any): any {
  yield put({ type: PROGRAMS_FETCH_REQUESTING })
  const data: IProgramObject[] | false = yield call(programsFetchAction, payload)
  if (!data) {
    return yield put({
      type: PROGRAMS_FETCH_ERROR,
    })
  }
  return yield put({
    type: PROGRAMS_FETCH_SUCCESS,
    payload: data,
  })
  /*return yield put({
    type: VIEWS_UPDATE,
  })*/
}

function* viewsUpdate({ payload }: any): any {
  const views: number[] = yield select((state: IStore) => state.reader.views)
  const data: IViewExtended[] | false = yield call(viewsUpdateAction, views)
  if (data) {
    return yield put({
      type: VIEWS_UPDATE_SUCCESS,
      payload: data,
    })
  }
}
