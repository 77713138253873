import React from 'react'
import Select from 'react-select'
import logo from './logo.svg'
import './App.css'
import UsersRecents from './UsersRecents'
import UserDetails from './UserDetails'
import ProgramsStats from './ProgramsStats'
import API from '../../../tools/fetch'

import { connect } from 'react-redux'
import loaderGif from '../../../assets/images/loader.gif'
import './userPremium.scss'
import InputText from '../../../components/Inputs/InputText'
import { ChevronLeft, ChevronRight, User } from 'react-feather'
import theme from '../../../theme'

class DataWrapper extends React.Component {
  timer = null
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      notes: [],
      companyPremiumId: '',
      errored: false,
    }
  }
  componentDidMount() {
    this.loadDatas()
  }
  async loadDatas() {
    //loadData
    const request = await API.post('/datas/user/notes', {
      pCompanyId: this.state.companyPremiumId,
    })
    console.log(request.data)
    this.setState(
      {
        notes: request.data,
      },
      () => setTimeout(() => this.setState({ loaded: true }), 200),
    )
  }
  render() {
    return (
      <div className="userPremium">
        <h1 style={{ width: '100%', textAlign: 'center', fontSize: '32px', fontWeight: 100 }}>Notes des formations</h1>
        {this.props.user.premiumCompany.name === 'Fasterclass' && (
          <div>
            <div className="userPremium__filtresContainer">
              <div>
                <div style={{ marginTop: '10px', marginBottom: '5px' }}>[SuperAdmin] Company Premium ID </div>
                <InputText
                  name={'CPI'}
                  containerStyle={{ marginTop: '5px' }}
                  value={this.state.companyPremiumId}
                  onChange={this.onCPIChange}
                />
              </div>
            </div>
          </div>
        )}
        {this.renderContent()}
      </div>
    )
  }
  renderContent() {
    if (!this.state.loaded)
      return (
        <div className="onlineRouter__loader">
          <img src={loaderGif} />
        </div>
      )
    return (
      <div className="userPremium__displayContainer">
        {this.props.programs.map((program) => {
          let progNotes = {}
          let totalCount = 0
          let totalNote = 0
          program.episodes.forEach((ep) => {
            let note = this.state.notes.find((n) => n.id === ep.id) || { id: ep.id, total: 0, count: 0 }
            progNotes[ep.id] = note
            totalNote += parseInt(note.total)
            totalCount += parseInt(note.count)
          })
          return (
            <div style={{ borderLeft: `7px solid ${theme.colors.primary.regular}` }}>
              <h3 style={{ paddingTop: '8px' }}>
                <span style={{ color: theme.colors.primary.regular }}>{program.title.replace('$', '')}</span> -{' '}
                {Math.round((totalNote / totalCount) * 10) / 10}⭐ ({totalCount} notes)
              </h3>

              <div className="tableContainer">
                <table>
                  <tr>
                    <th>Episode</th>
                    {program.episodes.map((e) =>
                      progNotes[e.id].count === 0 ? null : (
                        <th>
                          {e.order} - {e.title}
                        </th>
                      ),
                    )}
                  </tr>
                  <tr>
                    <th>Note moyenne</th>
                    {program.episodes.map((e) =>
                      progNotes[e.id].count === 0 ? null : (
                        <td>{Math.round((progNotes[e.id].total / progNotes[e.id].count) * 10) / 10}⭐</td>
                      ),
                    )}
                  </tr>
                  <tr>
                    <th>Nb note</th>
                    {program.episodes.map((e) =>
                      progNotes[e.id].count === 0 ? null : <td>{progNotes[e.id].count}</td>,
                    )}
                  </tr>
                </table>
              </div>
            </div>
          )
        })}
      </div>
    )
  }
  onCPIChange = (name, value) => {
    this.setState({ companyPremiumId: value, loaded: false, page: 0 }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.loadDatas()
      }, 600)
    })
  }
}

export default connect((store) => ({
  programs: store.programs.programs,
  user: store.user.data,
}))(DataWrapper)
