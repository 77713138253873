const theme: ITheme = {
  colors: {
    primary: {
      light: '#49FFC1',
      lighter: '#C789FE',
      regular: '#49FFC1',
      dark: '#2cb185',
      darker: '#1f7559',
    },
    greys: {
      lighter: '#4A4A4A',
      light: '#34293E',
      regular: '#281E30',
      dark: '#212121',
      darkest: '#000000',
    },
    white: '#ffffff',
    error: '#FF4D66',
    success: '#00D377',
  },
}
export default theme
interface ITheme {
  colors: {
    primary: {
      light: string
      lighter: string
      regular: string
      dark: string
      darker: string
    }
    greys: {
      light: string
      regular: string
      dark: string
      lighter: string
      darkest: string
    }
    white: string
    error: string
    success: string
  }
}
