const locales: any = {
  fr: {
    errors: {
      USER_NOT_FOUND: 'Cet email est inconnu, avez vous utilisé une autre adresse ?',
      UNKNOWN_ERROR: `Une erreur inconnue s'est produite, merci de réessayer ultérieurement ou de contacter le support.`,
      WRONG_PASSWORD_OR_EMAIL: `Le mot de passe est incorrect`,
    },
    pages: {
      online: {
        bottomBar: {
          home: 'Programmes',
          chat: 'Q & A',
          profil: 'Profil',
        },
      },
      offline: {
        home: {
          h1: 'Bienvenue',
          link: 'En savoir plus.',
          linkTarget: 'https://www.fasterclass.fr',
          loginBtn: 'Se connecter',
          registerBtn: `S'inscrire`,
          puce1: 'Contenus exclusifs 100% vidéo & audio',
          puce2: 'Espace Q&A personnel',
          puce3: 'Fiches de synthèses & Ressources',
        },
        login: {
          title: 'Connexion',
          inputEmail: 'E-mail',
          inputPwd: 'Mot de passe',
          loginBtn: 'Se connecter',
        },
        register: {
          title: 'Inscription',
          firstname: 'Prénom',
          lastname: 'Nom',
          company: 'Entreprise',
          inputEmail: 'E-mail',
          inputPwd: 'Mot de passe',
          registerBtn: `S'inscrire`,
        },
      },
    },
  },
}
export default locales
