import { takeLatest, put, call, delay } from 'redux-saga/effects'
import {
  LOGIN,
  LOGIN_REQUEST,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
  USER_LOGOUT_PROCESS,
  USER_LOGOUT,
  USER_UPDATE,
  USER_UPDATE_REQUESTING,
  USER_UPDATE_SUCCESS,
  USER_UPDATE_ERROR,
  USER_UPDATE_PWD,
  USER_UPDATE_PWD_REQUESTING,
  USER_UPDATE_PWD_ERROR,
  USER_UPDATE_PWD_SUCCESS,
  USER_UPDATE_PWD_KNOWN_ERROR,
  USER_RELOAD_DATA,
} from './constants'
import { loginAction, fetchMe, updateAction, updatePasswordAction } from './actions'
import { setToken, unsetToken } from '../../tools/token'
import { APP_OPENED, USER_JUST_REGISTERED } from '../trackEvent/constants'
import { PROGRAMS_FETCH } from '../programs/constants'

export default function* userWatcher(): IterableIterator<any> {
  yield takeLatest([LOGIN], loginRequest)
  yield takeLatest([USER_UPDATE], updateSaga)
  yield takeLatest([USER_UPDATE_PWD], updatePwdSaga)
  yield takeLatest([USER_RELOAD_DATA], userReloadData)
  return yield takeLatest([USER_LOGOUT_PROCESS], logoutRequest)
}

function* logoutRequest({ payload }: any): any {
  unsetToken()
  yield delay(500)
  yield put({ type: USER_LOGOUT })
}
function* updateSaga({ payload }: any): any {
  yield put({ type: USER_UPDATE_REQUESTING })
  const data: false | object = yield call(updateAction, payload)
  if (!data) {
    return yield put({ type: USER_UPDATE_ERROR })
  }
  return yield put({ type: USER_UPDATE_SUCCESS, payload: data })
}
function* updatePwdSaga({ payload }: any): any {
  yield put({ type: USER_UPDATE_PWD_REQUESTING })
  const data: false | object = yield call(updatePasswordAction, payload.values)
  if (typeof data === 'boolean' && data) {
    return yield put({ type: USER_UPDATE_PWD_SUCCESS })
  }
  // @ts-ignore
  if (typeof data === 'object' && data[0] && data[0] === 'WRONG_PASSWORD') {
    payload.injectErrors({ actualPassword: ['Le mot de passe est incorrect.'] })
    return yield put({ type: USER_UPDATE_PWD_KNOWN_ERROR })
  }
  return yield put({ type: USER_UPDATE_PWD_ERROR })
}

function* loginRequest({ payload }: any): any {
  yield put({ type: LOGIN_REQUEST })
  const data: { reload: boolean; token: string } | false | string = yield call(loginAction, payload)

  if (!data || typeof data === 'string') {
    if (!data) {
      return yield put({
        type: LOGIN_ERROR,
        payload: 'UNKNOWN_ERROR',
      })
    }
    return yield put({
      type: LOGIN_ERROR,
      payload: data,
    })
  }
  if (data.reload) {
    setToken(data.token)
    yield delay(200)
    let user: any = yield call(fetchMe)

    if (!user) {
      return yield put({
        type: LOGIN_ERROR,
        payload: 'UNKNOWN_ERROR',
      })
    }
    if (payload.registered) {
      yield put({
        type: USER_JUST_REGISTERED,
      })
    }
    return yield put({
      type: LOGIN_SUCCESS,
      payload: user,
    })
  }
}
function* userReloadData({ payload }: any): any {
  let user: any = yield call(fetchMe)
  if (user) {
    yield put({
      type: LOGIN_SUCCESS,
      payload: user,
    })
  }

  yield put({
    type: PROGRAMS_FETCH,
  })
  return yield put({
    type: APP_OPENED,
  })
}
