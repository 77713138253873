import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo_fasterclass.png'
import './Header.scss'
import { User } from 'react-feather'
import NavMenu from './NavMenu'
import Popup from 'reactjs-popup'
import { IPremiumCompanyObject } from '../../store/user/reducer'
import imageResizer from '../../tools/imageResizer'

interface IProps {
  firstname: string
  premiumCompany: IPremiumCompanyObject | undefined
  processLogout: () => void
}
interface IState {
  menuOpened: boolean
  sponsorOpened: boolean
}

class Header extends React.Component<IProps, IState> {
  public constructor(props: IProps) {
    super(props)
    this.state = { menuOpened: false, sponsorOpened: false }
  }
  componentDidMount() {
    document.addEventListener('mousedown', this.closeMenu, true)
  }
  componentWillUnmount() {
    document.removeEventListener('mousedown', this.closeMenu, true)
  }
  private closeMenu = () => {
    if (this.state.menuOpened || this.state.sponsorOpened) {
      setTimeout(() => this.setState({ menuOpened: false, sponsorOpened: false }), 200)
    }
  }
  public render(): JSX.Element {
    const { premiumCompany } = this.props
    console.log(premiumCompany)
    return (
      <div className="header">
        <div className="header__container">
          <Link to="/online/" className="header__logo-box">
            <div className="header__logo">
              FASTER<span>BOARD</span>
            </div>
          </Link>

          <div className="header__options">
            {premiumCompany && (
              <div className="header__usermenu" onClick={this.toggleMenu}>
                <img src={imageResizer(premiumCompany.logo, 'medium')} alt="" />
              </div>
            )}
            <div className="header__options-secondary">Bonjour {this.props.firstname}</div>
          </div>
        </div>
      </div>
    )
  }
  private toggleMenu = () => {
    this.setState({ menuOpened: !this.state.menuOpened })
  }
  private toggleSponsor = () => {
    this.setState({ sponsorOpened: !this.state.sponsorOpened })
  }
}

export default Header
