import { USER_LOGOUT } from '../user/constants'
import { PROGRAMS_FETCH, PROGRAMS_FETCH_ERROR, PROGRAMS_FETCH_REQUESTING, PROGRAMS_FETCH_SUCCESS } from './constants'

export interface IProgramObject {
  id: number
  description: string
  title: string
  picture: string
  titlePicture: string
  certification: string
  price: number
  available: string
  purchase: {
    date: string
    price: number
    complete: boolean
    status: string
  } | null
  mentor: IMentorObject
  episodes: IEpisodeObject[]
  buyUrl: string
  horsserie: boolean
  cpfready: boolean
}

export interface IEpisodeObject {
  id: number
  title: string
  description: string
  picture: string
  duration: number
  postit: string
  order: number
  chapter: string
  video: string
  audio: string
  mentor: IMentorObject
  view: IViewObject | null
  ressources: IRessourceObject[]
  subtitles: ISubtitleObject[]
  introduction: boolean
  trailer: boolean
  free: boolean
  videoid?: string
}

export interface IViewObject {
  currentDuration: number
  complete: boolean
  date: string
  note: number
  type: string
}

export interface IRessourceObject {
  id: number
  image: string
  title: string
  type: string
  file: string
}

export interface IMentorObject {
  id: number
  firstname: string
  lastname: string
  picture: string
  bio: string
  description: string
  experiences: IExperienceObject[]
}

export interface IExperienceObject {
  id: number
  picture: string
  company: string
  job: string
  tag: string
  companySize: number
  companyTime: number
  order: number
}

export interface IProgramsState {
  isRequesting: boolean
  programs: IProgramObject[]
  error: boolean
}

export interface ISubtitleObject {
  id: number
  lang: string
  langShort: string
  url: string
}

const initialState: IProgramsState = {
  isRequesting: true,
  programs: [],
  error: false,
}

export default (
  state: IProgramsState = initialState,
  { type, payload }: { type: string; payload: any },
): IProgramsState => {
  switch (type) {
    case PROGRAMS_FETCH_REQUESTING:
      return {
        ...state,
        isRequesting: true,
        error: false,
      }
    case PROGRAMS_FETCH_ERROR:
      return {
        ...state,
        isRequesting: false,
        error: true,
      }
    case PROGRAMS_FETCH_SUCCESS:
      return {
        ...state,
        programs: payload,
        isRequesting: false,
        error: false,
      }

    case USER_LOGOUT:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
