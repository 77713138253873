import createSagaMiddleware, { SagaMiddleware } from 'redux-saga'
import { createStore as createReduxStore, applyMiddleware, compose, combineReducers } from 'redux'
// @ts-ignore
import { persistStore, WebStorage, Persistor } from 'redux-persist'
// @ts-ignore
import storage from 'redux-persist/lib/storage'

import userReducer, { IUserState } from './user/reducer'
import readerState, { IReaderState } from './reader/reducer'
import trackerState, { ITrackerState } from './trackEvent/reducer'
import programsReducer, { IProgramsState } from './programs/reducer'
import mentorPopupReducer, { IMentorPopupState } from './mentorPopup/reducer'

import userWatcher from './user/saga'
import programsWatcher from './programs/saga'
import trackEventsWatcher from './trackEvent/saga'

// @ts-ignore
const composeEnhancer: any = compose
const sagaMiddleware: SagaMiddleware<any> = createSagaMiddleware()

const persistConfig: {
  key: string
  storage: WebStorage
  whitelist: string[]
} = {
  key: 'root',
  storage,
  whitelist: [], // Only whitelist will be stored
}

export interface IStore {
  user: IUserState
  programs: IProgramsState
  reader: IReaderState
  tracker: ITrackerState
  mentorPopup: IMentorPopupState
}

const createStore: any = (): any => {
  const middlewares: any = [sagaMiddleware]
  const enhancers: any = []

  const appReducer: any = combineReducers({
    mentorPopup: mentorPopupReducer,
    user: userReducer,
    programs: programsReducer,
    reader: readerState,
    tracker: trackerState,
  })

  const rootReducer: any = (state: any, action: any): any => {
    return appReducer(state, action)
  }

  const redux: any = createReduxStore(rootReducer, compose(applyMiddleware(...middlewares), ...enhancers))

  redux.asyncReducers = {}

  sagaMiddleware.run(userWatcher)
  sagaMiddleware.run(programsWatcher)
  sagaMiddleware.run(trackEventsWatcher)

  return redux
}

export const store: any = createStore()
export const persistor: Persistor = persistStore(store)
