import React from 'react'
import Select from 'react-select'
import logo from './logo.svg'
import './App.css'
import UsersRecents from './UsersRecents'
import UserDetails from './UserDetails'
import ProgramsStats from './ProgramsStats'
import API from '../../../tools/fetch'
import moment from 'moment'

import { connect } from 'react-redux'
import loaderGif from '../../../assets/images/loader.gif'
import './userPremium.scss'
import InputText from '../../../components/Inputs/InputText'
import { ChevronLeft, ChevronRight, FileText, User } from 'react-feather'
import theme from '../../../theme'
import Button from '../../../components/Button'

const options = [
  { value: 'mrd', label: 'Vues les plus récentes' },
  { value: 'mld', label: 'Activité les plus récentes' },
  { value: 'idt', label: 'Derniers inscrits' },
]

class DataWrapper extends React.Component {
  timer = null
  constructor(props) {
    super(props)
    this.state = {
      loaded: false,
      users: [],
      count: 0,
      search: '',
      order: { value: 'mrd', label: 'Vues les plus récentes' },
      companyPremiumId: '',
      nbByPage: 10,
      page: 0,
      errored: false,
      exportLoading: false,
    }
  }
  componentDidMount() {
    this.loadDatas()
  }
  async loadDatas() {
    //loadData
    const request = await API.post(
      '/datas/user/premium',
      {
        search: this.state.search,
        page: this.state.page,
        nbByPage: this.state.nbByPage,
        pCompanyId: this.state.companyPremiumId,
        order: this.state.order.value,
      },
      { timeout: 30000 },
    )
    console.log(request.data)
    this.setState(
      {
        users: request.data.users,
        count: request.data.count,
      },
      () => setTimeout(() => this.setState({ loaded: true }), 200),
    )
  }
  downloadCSV = () => {
    if (!this.state.exportLoading) {
      this.setState({ exportLoading: true }, () => {
        API({
          url: '/datas/user/premium/xls', //your url
          method: 'GET',
          responseType: 'blob', // important
          params: {
            search: this.state.search,
            pCompanyId: this.state.companyPremiumId,
            order: this.state.order.value,
          },
          timeout: 30000,
        }).then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]))
          const link = document.createElement('a')
          link.href = url
          link.setAttribute('download', 'fasterclass-export-users-' + moment().format('YYYYMMDDHHmmss') + '.xlsx') //or any other extension
          document.body.appendChild(link)
          link.click()
          this.setState({ exportLoading: false })
        })
      })
    }
  }
  render() {
    return (
      <div className="userPremium">
        <h1 style={{ width: '100%', textAlign: 'center', fontSize: '32px', fontWeight: 100 }}>
          Suivi des utilisateurs premiums
        </h1>
        <div>
          <div className="userPremium__filtresContainer">
            <div style={{ marginTop: '5px', marginBottom: '5px' }}>Recherche</div>
            <InputText
              name={'search'}
              containerStyle={{ marginTop: '5px' }}
              value={this.state.search}
              onChange={this.onSearchChange}
            />
            <div style={{ marginTop: '10px', marginBottom: '5px' }}>Ordre</div>
            <Select
              value={this.state.order}
              onChange={this.onOrderChange}
              options={options}
              classNamePrefix="filtresSelect"
              className="filtresSelect"
            />
            {this.props.user &&
              this.props.user.premiumCompany &&
              this.props.user.premiumCompany.name === 'Fasterclass' && (
                <div>
                  <div style={{ marginTop: '10px', marginBottom: '5px' }}>[SuperAdmin] Company Premium ID </div>
                  <InputText
                    name={'CPI'}
                    containerStyle={{ marginTop: '5px' }}
                    value={this.state.companyPremiumId}
                    onChange={this.onCPIChange}
                  />
                </div>
              )}
          </div>
        </div>
        {this.renderContent()}
      </div>
    )
  }
  renderContent() {
    if (!this.state.loaded)
      return (
        <div className="onlineRouter__loader">
          <img src={loaderGif} />
        </div>
      )
    return (
      <div className="userPremium__displayContainer">
        <div className="userPremium__textCount">
          <User size={18} style={{ marginRight: '6px' }} />
          {this.state.count > 0
            ? `${this.state.count} utilisateur(s) correspondant(s) à cette recherche`
            : `Aucun utilisateur ne correspond à votre recherche`}

          <Button onClick={this.downloadCSV} className="userPremium__textCountExport">
            <FileText size={16} style={{ marginRight: '6px' }} />
            {this.state.exportLoading ? `Chargement...` : `Export xlsx`}
          </Button>
        </div>
        {this.state.count > 0 && this.renderPagination()}
        {this.state.users.map((user) => {
          return <UserDetails user={user} programs={this.props.programs} />
        })}
        {this.state.count > 0 && this.renderPagination()}
      </div>
    )
  }
  renderPagination = () => {
    let prevPage = this.state.page > 0
    let nextPage = (this.state.page + 1) * this.state.nbByPage < this.state.count
    let nbPage = Math.round(this.state.count / this.state.nbByPage) + 1
    return (
      <div className="userPremium__pagination">
        {prevPage ? (
          <div onClick={this.onPrevPage} className="userPremium__paginationLink">
            <ChevronLeft color={theme.colors.white} />
          </div>
        ) : (
          <div className="userPremium__paginationLinkLock">
            <ChevronLeft color={`${theme.colors.white}50`} />
          </div>
        )}

        <div className="userPremium__paginationText">
          {this.state.page + 1} / {nbPage}
        </div>
        {nextPage ? (
          <div onClick={this.onNextPage} className="userPremium__paginationLink">
            <ChevronRight color={theme.colors.white} />
          </div>
        ) : (
          <div className="userPremium__paginationLinkLock">
            <ChevronRight color={`${theme.colors.white}50`} />
          </div>
        )}
      </div>
    )
  }
  onPrevPage = () => {
    this.setState({ loaded: false, page: this.state.page - 1 }, () => {
      this.loadDatas()
    })
  }
  onNextPage = () => {
    this.setState({ loaded: false, page: this.state.page + 1 }, () => {
      this.loadDatas()
    })
  }
  onSearchChange = (name, value) => {
    this.setState({ search: value, loaded: false, page: 0 }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.loadDatas()
      }, 600)
    })
  }
  onOrderChange = (selectedOption) => {
    this.setState({ order: selectedOption, loaded: false, page: 0 }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.loadDatas()
      }, 600)
    })
  }
  onCPIChange = (name, value) => {
    this.setState({ companyPremiumId: value, loaded: false, page: 0 }, () => {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.loadDatas()
      }, 600)
    })
  }
}

export default connect((store) => ({
  programs: store.programs.programs,
  user: store.user.data,
}))(DataWrapper)
