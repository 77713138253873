import { IViewExtended } from '../reader/reducer'
import { IEpisodeObject, IProgramObject } from './reducer'

export const getCurrentEpisodeToPlay = (
  program: IProgramObject | undefined,
  views: IViewExtended[],
  includeIntroduction = true,
): IEpisodeObject | undefined => {
  if (!program) return undefined
  let episode: IEpisodeObject | undefined = undefined
  if (isPlayableProgram(program)) {
    episode = getNextEpisode(program, views)
  } else {
    if (includeIntroduction) episode = program.episodes.find((e) => e.introduction)
  }
  return episode
}

export const getNextEpisodeToPlay = (
  program: IProgramObject | undefined,
  currentEpisodeId: number | undefined,
  views: IViewExtended[],
): IEpisodeObject | undefined => {
  if (!program) return undefined
  if (!currentEpisodeId) return getCurrentEpisodeToPlay(program, views)
  let next: boolean = false
  let nextPlayableEpisode: IEpisodeObject | undefined = getCurrentEpisodeToPlay(program, views)
  if (nextPlayableEpisode && nextPlayableEpisode.id !== currentEpisodeId) return nextPlayableEpisode
  let nextNaturalEpisode: IEpisodeObject | undefined = undefined
  program.episodes.forEach((e) => {
    if (next) {
      nextNaturalEpisode = e
      next = false
    } else {
      if (e.id === currentEpisodeId) {
        next = true
      }
    }
  })
  return nextNaturalEpisode
}

export const getNextEpisode = (program: IProgramObject, views: IViewExtended[]): IEpisodeObject => {
  let notFinished: IEpisodeObject[] = []
  program.episodes.forEach((e) => {
    let view = views.find((v) => e.id === v.episodeId)
    if ((!view || !view.complete) && !e.trailer) {
      notFinished.push(e)
    }
  })
  if (notFinished.length > 0) {
    return notFinished[0]
  }

  let epDef = program.episodes.find((e) => !e.trailer && !e.introduction)
  return epDef || program.episodes[1]
}

export const getNotFinishedEpisode = (program: IProgramObject, views: IViewExtended[]): IEpisodeObject[] => {
  let notFinished: IEpisodeObject[] = []
  program.episodes.forEach((e) => {
    let view = views.find((v) => e.id === v.episodeId)
    if ((!view || !view.complete) && !e.introduction && !e.trailer && e.chapter !== 'conclusion') {
      notFinished.push(e)
    }
  })
  return notFinished
}

export const isPlayableProgram = (program: IProgramObject | undefined): boolean => {
  if (!program) return false
  if (!program.purchase) return false
  if (program.purchase.status === 'GOT') return true
  return false
}

export const isProgramCompleted = (program: IProgramObject | undefined, views: IViewExtended[]): boolean => {
  if (!program) return false
  if (getNotFinishedEpisode(program, views).length === 0) return true
  return false
}
export const getNBRessources = (program: IProgramObject | undefined): string => {
  if (!program) return '0'
  let count = 0
  program.episodes.forEach((e) => (count += e.ressources ? e.ressources.length : 0))
  return count.toString()
}
export const getNBFasterclass = (program: IProgramObject | undefined): string => {
  return getNBFasterclassInt(program).toString()
}

export const getNBFasterclassInt = (program: IProgramObject | undefined): number => {
  if (!program) return 0
  let count = 0
  program.episodes.forEach((e) => (count += !e.introduction && !e.trailer && e.chapter !== 'conclusion' ? 1 : 0))
  return count
}
export const getProgramPercent = (program: IProgramObject | undefined, views: IViewExtended[]): number => {
  if (!program) return 0
  let nbFC: number = getNBFasterclassInt(program)
  let nbNotFinished: number = getNotFinishedEpisode(program, views).length
  return Math.round((1 - nbNotFinished / (nbFC > 0 ? nbFC : 1)) * 100)
}
