import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect, Link } from 'react-router-dom'

import Header from '../../components/Header/Header'
import { IStore } from '../../store'
import { IUserObject } from '../../store/types'
import { USER_LOGOUT_PROCESS, USER_RELOAD_DATA } from '../../store/user/constants'
import Episode from './Episode'
import Home from './Home'
import FCOnly from './FCOnly/DataWrapper'
import PremiumUsers from './PremiumUsers/DataWrapper'
import PremiumNotes from './PremiumNotes/DataWrapper'
import Settings from './Settings'
import './OnlineRouter.scss'
import ChangePassword from './ChangePassword'
import intercom from '../../tools/intercom'
import { PROGRAMS_FETCH } from '../../store/programs/constants'
import Loader from '../../components/Loader'
import ProgramRouter from './ProgramRouter'
import { AlertOctagon, AtSign, ChevronRight, HelpCircle, LogOut, MessageSquare, UserPlus } from 'react-feather'
import theme from '../../theme'

interface IProps {
  user: IUserObject
  dispatch: (props: { type: string; payload?: any }) => void
  loadingPrograms: boolean
  loadingUser: boolean
}

class OnlineRouter extends React.Component<IProps> {
  private interval: any = undefined

  public componentDidMount() {
    this.props.dispatch({ type: USER_RELOAD_DATA })
  }
  public componentWillUnmount() {
    intercom.shutdown()
  }
  render() {
    return (
      <div className="onlineRouter">
        <Loader loading={this.props.loadingPrograms} />
        <Header
          firstname={this.props.user.firstname || ''}
          processLogout={this.processLogout}
          premiumCompany={this.props.user.premiumCompany}
        />

        {!this.props.loadingPrograms && (
          <div className="onlineRouter__container">
            {this.props.user.premiumCompany && (
              <div className="onlineRouter__leftContainer">
                {this.props.user.premiumCompany.name === 'Fasterclass' && (
                  <Link className="onlineRouter__menuLink" to="/online/FCOnly">
                    Suivi des utilisateurs BtoC [FConly] <ChevronRight color={theme.colors.white} />
                  </Link>
                )}
                <Link className="onlineRouter__menuLink" to="/online/premiumUsers">
                  Suivi des utilisateurs premiums <ChevronRight color={theme.colors.white} />
                </Link>
                <Link className="onlineRouter__menuLink" to="/online/premiumNotes">
                  Notes des formations <ChevronRight color={theme.colors.white} />
                </Link>
                <div
                  className="onlineRouter__menuLink"
                  onClick={() =>
                    intercom.showNewMessage(
                      `Bonjour, j'aimerais ajouter ou supprimer un nom de domaine bénéficiant de mon offre premium.`,
                    )
                  }
                >
                  Ajouter un nom de domaine
                  <AtSign color={theme.colors.white} />
                </div>
                <div
                  className="onlineRouter__menuLink"
                  onClick={() =>
                    intercom.showNewMessage(
                      `Bonjour, j'aimerais ajouter ou supprimer des utilisateurs premium sur Fasterclass.`,
                    )
                  }
                >
                  Ajouter des utilisateurs
                  <UserPlus color={theme.colors.white} />
                </div>
                <div
                  className="onlineRouter__menuLink"
                  onClick={() =>
                    intercom.showNewMessage(`Bonjour, j'aimerais faire une suggestion sur l'application Fasterboard.`)
                  }
                >
                  Faire une suggestion
                  <MessageSquare color={theme.colors.white} />
                </div>
                <div
                  className="onlineRouter__menuLink"
                  onClick={() => intercom.showNewMessage(`Bonjour, j'aimerais de l'aide sur le Fasterboard.`)}
                >
                  Demander de l'aide
                  <HelpCircle color={theme.colors.white} />
                </div>
                <div
                  className="onlineRouter__menuLink"
                  onClick={() =>
                    intercom.showNewMessage(`Bonjour, j'aimerais remonter un bug que j'ai trouvé sur le Fasterboard.`)
                  }
                >
                  Signaler un bug
                  <AlertOctagon color={theme.colors.white} />
                </div>
                <div className="onlineRouter__menuLink onlineRouter__logout" onClick={this.processLogout}>
                  Se déconnecter
                  <LogOut color={theme.colors.error} />
                </div>
              </div>
            )}
            <div className="onlineRouter__mainContainer">
              <Switch>
                <Route exact path="/online/" component={PremiumUsers} />
                <Route exact path="/online/FCOnly" component={FCOnly} />
                <Route exact path="/online/premiumUsers" component={PremiumUsers} />
                <Route exact path="/online/premiumNotes" component={PremiumNotes} />
                <Redirect to="/online/" />
              </Switch>
            </div>
          </div>
        )}
      </div>
    )
  }

  private processLogout = () => {
    this.props.dispatch({ type: USER_LOGOUT_PROCESS })
  }
}

export default connect((store: IStore) => ({
  user: store.user.data,
  loadingUser: store.user.isRequesting,
  loadingPrograms: store.programs.isRequesting,
}))(OnlineRouter)
